import React, {Component} from 'react';
//import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Router from './routes'

class App extends Component {
    render(){
        return(
            <Router/>
        )
    }
}

export default App;
