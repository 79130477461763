import PropTypes from 'prop-types';
import CurrencyDollarIcon from '@heroicons/react/24/solid/CurrencyDollarIcon';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';

import Calendar from '../../assets/icons/calendario.png'




function OverviewTotalProfit(props) {
  const { label, value, sx, hexcolor, colorIcon, icon } = props;

  return (
    <Card style={{ backgroundColor: hexcolor, color: "#fff", marginTop: "58px"}}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={1}

        >
          <Stack spacing={1}>
            <Typography
              variant="overline"
            >
              {label}
            </Typography>
            {label === 'Prazo de Vigência' ?<Typography fontSize={"15px"} style={{"font-weight": "bold", "margin-top":"17px"}}>{value}</Typography> : <Typography fontSize={"15px"} style={{"font-weight": "bold", "margin-top":"17px"}}  >{value}</Typography>}
            
          </Stack>
          <Avatar
            sx={{
              backgroundColor: colorIcon,
              height: 56,
              width: 56
            }}
          >

          {label === 'Prazo de Vigência' ?<img src={Calendar} alt='s' style={{width: "35px" }}/> : <img src={icon} alt='s' style={{width: "50px"}}/>}

          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default OverviewTotalProfit;
