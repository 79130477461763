import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import HomeFinanceiroPB from './pages/PlugBase/Financeiro/Home';
import HomeFisicoFinanceiroBraskem from './pages/Braskem/FisicoFinanceiro/Home';
import HomeQualidadePB from './pages/PlugBase/Qualidade/Home';

export default function AppRouter(){
    return(
        <Router>
            <Routes>
                <Route path='/plugbase_financeiro' element={<HomeFinanceiroPB/>}/>
                <Route path='/braskem_fisico_financeiro' element={<HomeFisicoFinanceiroBraskem/>}/>
                <Route path='/plugbase_qualidade' element={<HomeQualidadePB/>}/>
            </Routes>
        </Router>
    )
}