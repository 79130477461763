import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);



const options={
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  },
  rotation: 0,
  circumference: 360,
  cutout: "70%",
  maintainAspectRatio: true,
  responsive: false
}

function CircularProgress(props){

const xValue = {
    xa: [],

  };

props.opcoesCheklistsGrauNaoConformidade.map(opcao => (
  xValue.xa.push(opcao.GRAU_NAO_CONFORMDIADE.toFixed(2))
  ))



const y =  100-xValue.xa[0]

const data = {
  datasets: [
    {
      data: [xValue.xa[0], y],
      backgroundColor: [
        "rgba(98, 193, 126, 1)",
        "#a0a0a0"
      ],
      display: true,
      borderColor: "#A1CAEB"
    }
  ]
};

  return (
    <div>
      <div className='header'>
        <h3 className='title' style={{color: "white"}}>Grau de Não Conformidades</h3>
      </div>
      <Doughnut data={data} options={options} />

      <div
        style={{
          position: "initial",
          top: "55%",
          left: "50%",
          transform: "translate(0%, -400%)",
          textAlign: "center",
          color: "#fff"
        }}
      >
        <div>{xValue.xa[0] + "%"}</div>
      </div>

    </div>
  );
};

export default CircularProgress;
