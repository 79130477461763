import React from 'react';
import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { height } from '@mui/system';
import plugin from 'chartjs-plugin-datalabels';
import { plugins } from 'chart.js';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const fakedatas = [{"MesAno":"2022-07-01T00:00:00.000Z","PREVISTO":5,"REALIZADO":2},{"MesAno":"2022-08-01T00:00:00.000Z","PREVISTO":0,"REALIZADO":1}]
const fakedatasNC = [{"MesAno":"Concreto","PREVISTO":5},{"MesAno":"Drenagem","PREVISTO":3}]
const options = {
  indexAxis: 'x',

  maintainAspectRatio: false,
  
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        color: 'white',
      },
      
      
    },
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        color: '#fff',
      },
      stacked: false,
    },
    
    y: {
      ticks: {
        beginAtZero: true,
        color: '#fff',
      },
      stacked: false,
    },
  },
};

function BarChart(props) {
  

  

  const data = {
    dataPrevisto: [],
    dataExecutado: [],
    labels: [],
  };

  const data2 = {
    labels: data.labels,
    datasets: [],
  };

  if (props.modulo === 'F'){
    {props.opcoesPrevistoXExecutado.map(opcao => (
      data.labels.push(opcao.MesAno.split('-')[1] +'/'+ opcao.MesAno.split('-')[0]), data.dataPrevisto.push(opcao.PREVISTO), data.dataExecutado.push(opcao.REALIZADO)
    ))}

    data2.datasets =  [{
      label: 'Realizado',
      type: 'line',
      data: data.dataExecutado.filter(number => number > 0),
      backgroundColor: '#eead2d',
      borderColor: '#eead2d',
      borderWidth: 1,
    },
  
    {
      label: 'Previsto',
      data: data.dataPrevisto,
      backgroundColor: 'rgba(98, 193, 126, 1)',
      borderColor: 'rgba(98, 193, 126, 1)',
      borderWidth: 1,
      
    },
  
    {
      label: 'Realizado',
      type: 'bar',
      data: data.dataExecutado,
      backgroundColor: '#A1CAEB',
      borderColor: '#A1CAEB',
      borderWidth: 1,
    },]
    
  } else if (props.modulo === 'QC'){
    {props.opcoesCheklistsAgrupadosPorData.map(opcao => (
      data.labels.push(opcao.MESES.split('-')[1] +'/'+ opcao.MESES.split('-')[0]), data.dataPrevisto.push(opcao.QTD_CHECKLISTS), data.dataExecutado.push(opcao.QTDS_PLANOACAO)
    ))}

    data2.datasets =  [
    {
      label: 'Checklists',
      data: data.dataPrevisto,
      backgroundColor: 'rgba(98, 193, 126, 1)',
      borderColor: 'rgba(98, 193, 126, 1)',
      borderWidth: 1,
      
    },
  
    {
      label: 'Não Conformidades',
      type: 'bar',
      data: data.dataExecutado,
      backgroundColor: '#A1CAEB',
      borderColor: '#A1CAEB',
      borderWidth: 1,
    },]

    options.scales.x.stacked = true;
    options.scales.y.stacked = true;
    options.plugins.legend.display = true;
  } else if (props.modulo === 'QNC'){
    {props.opcoesPlanoDeAcaoAgrupadoPorData.map(opcao => (
      data.labels.push(opcao.Checklist), data.dataPrevisto.push(opcao.qtds)
    ))}

    data2.datasets =  [
    {
      label: 'Não Conformidade1',
      data: data.dataPrevisto,
      backgroundColor: 'rgba(98, 193, 126, 1)',
      borderColor: 'rgba(98, 193, 126, 1)',
      borderWidth: 1,
      
    },
  ]
}
  options.indexAxis = props.eixo
  return (
    <div style={{ height: props.altura, color: '#fff', display: "grid" }}>
      <div className='header'>
        <h3 className='title'>{props.cabecalho}</h3>
      </div>
      <Bar data={data2} options={options}/>
    </div>
  )
};

export default BarChart;