import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { v4 as uuidv4} from 'uuid'

const columns = [
  { field: 'id', headerName: 'BM', width: 90 },
  {
    field: 'ValorMedido_WBS',
    headerName: 'Valor Medido',
    type: 'number',
    width: 150,
    valueGetter: (params) =>
    `R$ ${new Intl.NumberFormat('pt-BR').format(params.row.ValorMedido_WBS)}`
  },
  {
    field: 'ValorAcumulado_WBS',
    headerName: 'Valor Acumulado',
    type: 'string',
    width: 150,
    valueGetter: (params) =>
    `R$ ${new Intl.NumberFormat('pt-BR').format(params.row.ValorAcumulado_WBS)}`
    
   
  },
  {
    field: 'ValorPrevisto_WBS',
    headerName: 'Avanço',
    type: 'string',
    width: 110,
    valueGetter: (params) =>
    `${(params.row.ValorMedido_WBS / params.row.ValorAcumulado_WBS).toFixed(2)}%`
    
  },
  {
    field: 'DataInicio',
    headerName: 'Data Inicio',
    type: 'date',
    width: 110,
    valueGetter: (params) =>
      new Date(params.row.DataInicio)
    
  },
  {
    field: 'DataTermino',
    headerName: 'Data Final',
    type: 'date',
    width: 110,
    valueGetter: (params) =>
      new Date(params.row.DataTermino)
  },
  
];

const rows = [
  { id: uuidv4(), valorMedido: `R$ ${35.000}`, valorAcumulado: `R$ ${35.000}`, avanco: `${35} %`, dataInicio: new Date('Jul 12 2011'), dataFim: new Date('Jul 12 2011')},
  { id: uuidv4(), valorMedido: `R$ ${35.000}`, valorAcumulado: `R$ ${35.000}`, avanco: `${35} %`, dataInicio: new Date('Jul 12 2011'), dataFim: new Date('Jul 12 2011')},
  { id: uuidv4(), valorMedido: `R$ ${35.000}`, valorAcumulado: `R$ ${35.000}`, avanco: `${35} %`, dataInicio: new Date('Jul 12 2011'), dataFim: new Date('Jul 12 2011')},
  { id: uuidv4(), valorMedido: `R$ ${35.000}`, valorAcumulado: `R$ ${35.000}`, avanco: `${35} %`, dataInicio: new Date('Jul 12 2011'), dataFim: new Date('Jul 12 2011')},
 
];

export default function TabelaBms(props) {
  return (
    <Box sx={{ height: props.altura, "max-height": props.altura, width: '100%'}} >
      <DataGrid
        style={{color:"#fff"}}
        rows={props.opcoesDataTableBms}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}
