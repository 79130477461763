import Client from "../../../Autenticate/Auth";
//import {ObjectsApi, Autodesk } from 'forge-apis';

const Autodesk = window.Autodesk;
var getToken = { accessToken: Client.getAccesstoken()};

var viewer;

function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}




function filterModelBySetorBM(setor) {
    var valueSelected = [...setor]
    var selectedElements = [];

    function performSearch(value, callback) {
        try {
        viewer.search(value, function (e) {
            viewer.select([...e,...viewer.getSelection()]) // Armazena o elemento encontrado no array
            
        }, console.log(''))
    } catch (error){

    }
    }

    valueSelected.forEach(function(value) {
        performSearch(value, function() {
            // Callback opcional para executar ações após cada pesquisa
        });
    });

    console.log('GGA', selectedElements)

    try {
        viewer.select(selectedElements);
    } catch (error) {
        
    }
    

   

    // try {

    //     viewer.search(valueSelected, function (e) {
    //         viewer.select(e);
    //         //viewer.isolate(e), ["LcRevitData_Element:lcldrevit_parameter_-1010106"]
    //     }, console.log(''))
        
    // } catch (error) {
        
    // }

};

function launchViewer(div, urn) {

    const objectId = utf8_to_b64(urn)
    console.log(objectId)

    getToken.accessToken.then((token) => {

        var options = {
            'env': 'AutodeskProduction',
            'accessToken': token.access_token
        };


        Autodesk.Viewing.Initializer(options, function () {

            document.getElementById(div).innerHTML = "";
            var htmlDiv = document.getElementById(div);
            viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
            var startedCode = viewer.start();
            if (startedCode > 0) {
                console.error('Failed to create a Viewer: WebGL not supported.');
                return;
            }

            console.log('Initialization complete, loading a model next...');

        });

        var documentId = 'urn:' + objectId;
        Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);

        function onDocumentLoadSuccess(viewerDocument) {
            var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
            viewer.loadDocumentNode(viewerDocument, defaultModel);
        };

        function onDocumentLoadFailure() {
            console.error('Failed fetching Forge manifest');
        };

    })

};

export {launchViewer, filterModelBySetorBM};
