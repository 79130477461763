import {launchViewer, filterModelBySetorBM} from './ViewerFunction'
import React, {useEffect} from "react";

//const NOP_VIEWER = window.NOP_VIEWER;


function Viewer(props) {
    //const handleSelectChange = (event) => {
    //   console.log(NOP_VIEWER.getSelection())}

    useEffect(() => {
        filterModelBySetorBM(props.setorOption);
    }, [props.setorOption]); 

    useEffect(() => {
        launchViewer('viewerDiv', props.selectedOption);
    }, [props.selectedOption]); 
    

    {
        return (
            <div style={{ position: "relative", width: "100%", height: "100%"}} id="viewerDiv" />
        )
    }
}

export default Viewer;