import React, { Component } from "react";
import {MainQualidadePB} from "./QualidadePBHome";

class HomeQualidadePB extends Component {
    render() {
        return (
            <div style={{"margin":"15px"}}>
            <MainQualidadePB />
            </div>
        )
    }
}

export default HomeQualidadePB;