import React, { Component } from "react";
import {MainFinanceiroPB} from "./FinanceiroPBHome";

class HomeFinanceiroPB extends Component {
    render() {
        return (
            <div style={{"margin":"15px"}}>
            <MainFinanceiroPB />
            </div>
        )
    }
}

export default HomeFinanceiroPB;