import PropTypes from 'prop-types';
import ListBulletIcon from '@heroicons/react/24/solid/ListBulletIcon';
import iconBar from '../../assets/icons/barsChart.png'
import './index.css'

import {
  Avatar,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';

function OverviewTasksProgress(props) {
  const { label, value, sx } = props;

  let acumulado = 0
  let previsto = 100

  try {
    acumulado = parseFloat(value[value.length -1].ValorAcumulado_WBS)
    previsto = parseFloat(value[value.length -1].ValorPrevisto_WBS)
  } catch (error) {
    
  }

  return (
    <Card style={{ backgroundColor: "#313862", color: "#fff", marginTop: "58px" }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          <Stack spacing={0}>
            <Typography
              gutterBottom
              variant="overline"
            >
              {label}
            </Typography>
            <Typography variant="h5">
              {parseFloat(((acumulado/previsto)*100).toFixed(2))}%
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: '#62C17E',
              height: 56,
              width: 56
            }}
          >
            <img src={iconBar} alt='s' style={{width: "40px"}}/>
          </Avatar>
        </Stack>
        <Box sx={{ mt: 0 }}>
          <LinearProgress
            className="VolumeBar"
            value={parseFloat(((acumulado/previsto)*100).toFixed(2))}
            variant="determinate"
          />
          
        </Box>
      </CardContent>
    </Card>
  );
};

export default OverviewTasksProgress;
