import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { v4 as uuidv4} from 'uuid'


const windowSize = ([window.innerWidth, window.innerHeight]);
{console.log('AAAIII', windowSize)}
const columns = [
  { field: 'id',
  renderHeader: (params) => (
    <div style={{ textAlign: 'center' }}>{'Plano de Ação'}</div>
  ),
   width: window.innerWidth * 0.1},
  {
    field: 'Item',
    renderHeader: (params) => (
      <div style={{ textAlign: 'center' }}>{'Descrição'}</div>
    ),
    type: 'string',
    width:  window.innerWidth * 0.2,

  },
  {
    field: 'Solucao_DataAnalise',
    renderHeader: (params) => (
      <div style={{ textAlign: 'center' }}>{'Data de Análise'}</div>
    ),
    
    type: 'string',
    width:  window.innerWidth * 0.1,
    valueGetter: (params) =>
    (params.row.Solucao_DataAnalise)
    
   
  },
  {
    field: 'Data_Solucao',
    renderHeader: (params) => (
      <div style={{ textAlign: 'center' }}>{'Data Solucao'}</div>
    ),
    type: 'string',
    width:  window.innerWidth * 0.1,
    valueGetter: (params) =>
    (params.row.Data_Solucao),
    align: 'center',
    
  },
  {
    field: 'Status',
    renderHeader: (params) => (
      <div style={{ textAlign: 'center' }}>{'Status'}</div>
    ),
    
    type: 'string',
    width:  window.innerWidth * 0.1,
    valueGetter: (params) =>
     (params.row.Status)
    
  },
  {
    field: 'Data_Conclusao',
    headerName: 'Data de Correção',
    renderHeader: (params) => (
      <div style={{ textAlign: 'center' }}>{"Data Conclusao"}</div>
    ),
    type: 'string',
    width:  window.innerWidth * 0.1,
    valueGetter: (params) =>
      (params.row.Data_Conclusao)
  },
  {
    field: 'Solucao',
    renderHeader: (params) => (
      <div style={{ textAlign: 'center' }}>{'Solução'}</div>
    ),
    
    type: 'string',
    width:  window.innerWidth * 0.2,
    valueGetter: (params) =>
      params.row.Solucao
    
  },
  
];

const rows = [
  { id: uuidv4(), valorMedido: `R$ ${35.000}`, valorAcumulado: `R$ ${35.000}`, avanco: `${35} %`, dataInicio: new Date('Jul 12 2011'), dataFim: new Date('Jul 12 2011')},
  { id: uuidv4(), valorMedido: `R$ ${35.000}`, valorAcumulado: `R$ ${35.000}`, avanco: `${35} %`, dataInicio: new Date('Jul 12 2011'), dataFim: new Date('Jul 12 2011')},
  { id: uuidv4(), valorMedido: `R$ ${35.000}`, valorAcumulado: `R$ ${35.000}`, avanco: `${35} %`, dataInicio: new Date('Jul 12 2011'), dataFim: new Date('Jul 12 2011')},
  { id: uuidv4(), valorMedido: `R$ ${35.000}`, valorAcumulado: `R$ ${35.000}`, avanco: `${35} %`, dataInicio: new Date('Jul 12 2011'), dataFim: new Date('Jul 12 2011')},
 
];

export default function TabelaChecklists(props) {
  return (
    <Box sx={{ height: props.altura, "max-height": props.altura, width: '100%'}}>
      <DataGrid
        style={{color:"#fff"}}
        rows={props.opcoesDataTableCheklists}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}
