//import React, {Component} from "react";
import logo from '../../../assets/logo/logoPlug.png'
import Select from '@mui/material/Select';
import React, { Component, useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import BarChart from '../../../Components/Chats/BarChart';
import CircularProgress from '../../../Components/Chats/CircularProgressBar';
import ProgressChart from '../../../Components/Chats/ProgressChart';

import OverviewTotalProfit from '../../../Components/Tasks/overview-total-profit';
import OverviewTasksProgress from '../../../Components/Tasks/overview-tasks-progress';
import TabelaChecklists from '../../../Components/Tabelas/TableChecklists';
import TabelaServicosSetor from '../../../Components/Tabelas/ServicosBMs'
import Viewer from '../../../Components/PlugBase/Viewer/Viewer'
import { v4 as uuidv4 } from 'uuid'

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

//------------------
import iconMoeda from '../../../assets/icons/moeda.png'


import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
//----------


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#313862',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const PainelItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
    marginTop: '8px',
}));



function MainQualidadePB() {
    //Recebendo a URN do modelo selecionado no dropDown de Contrato e em seguida o texto do elemento
    const [opcoesContrato, setOpcoesContrato] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
    const [opcoesSetor, setOpcoesOpcoesSetor] = useState([]);
    const [setorOption, setSetorOption] = useState('');

    //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
    const [opcoesBMs, setOpcoesOpcoesBms] = useState([]);
    const [bmOption, setbBmOption] = useState('');

    //Recebendo a data de vigencia referente ao modelo selecionado no dropDown de Contrato
    const [opcoesDataVigencia, setopcoesDataVigencia] = useState([]);

    //Recebendo os dados do BM agrupados para poder atualizar a tabela de BMS
    const [opcoesDataTableBms, setOpcoesDataTableBms] = useState([]);

    //Recebendo os dados do serviços agrupados para poder atualizar a tabela de serviços
    const [opcoesDataTableServicos, setOpcoesDataTableServicos] = useState([]);

    //Recebendo os dados de previsto x executado para atualizar graficos
    const [opcoesPrevistoXExecutado, setOpcoesPrevistoXExecutado] = useState([]);

    //Recebendo os dados do serviços agrupados para poder atualizar a tabela de planos de ação
    const [opcoesDataTableCheklists, setOpcoesDataTableCheklists] = useState([]);

    //Recebendo os dados do serviços agrupados com informações de quantidades
    const [opcoesDataTableCheklistsQtds, setOpcoesDataTableCheklistsQtds] = useState([]);

    //Recebendo os dados do serviços agrupados por data para poder atualizar a tabela de planos de ação
    const [opcoesPlanoDeAcaoAgrupadoPorData, setOpcoesPlanoDeAcaoAgrupadoPorData] = useState([]);

    //Recebendo os dados do serviços agrupados por data com informações de quantidades
    const [opcoesCheklistsAgrupadosPorData, setOpcoesCheklistsAgrupadosPorData] = useState([]);

    //Recebendo os dados de grau de não conformidade
    const [opcoesCheklistsGrauNaoConformidade, setOpcoesCheklistsGrauNaoConformidade] = useState([]);

    //const [valoresPrevistoXExecutado, setvaloresPrevistoXExecutado] = useState([]);

    const [setorLabel, setSetorLabel] = useState('Setor');


    const [opcoesSetorDrop, setOpcoesSetorDrop] = useState([]);

    const handleSelectChangeSetor = (event) => {

        const setorValue = event.target.value

        typeof setorValue === 'string' ? console.log('É String') : console.log('não é String')
        setOpcoesSetorDrop(
            // On autofill we get a stringified value.
            typeof setorValue === 'string' ? setorValue.split(',') : setorValue
        );


        const urnValue = document.querySelector("#contratoIdSelect").innerText;
        const bmValue = parseInt(document.querySelector("#bmIdSelect").innerText) > 0 ? document.querySelector("#bmIdSelect").innerText : '';
        console.log(bmValue.length, '<<<<<<<<<<<<')


        setSetorOption(setorValue);

/*
        fetch('https://forge.tpfe.madrix.app:5000/dadostabelabms?' + 'numContrato=' + urnValue + '&setor=' + setorValue + '&bm=' + bmValue + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => (setOpcoesDataTableBms(data),
                setvaloresPrevistoXExecutado(data.length > 0 ? [data[data.length - 1]] : data)//[data[data.length - 1]]
            ));
*/

        fetch('https://forge.tpfe.madrix.app:5000/dadostabelaservices?' + 'numContrato=' + urnValue + '&setor=' + setorValue + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesDataTableServicos(data));

        /**
        fetch('https://forge.tpfe.madrix.app:5000/dadoschartdata' + urnValue.split('/')[1].slice(0, 8))
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesPrevistoXExecutado(data));
            */
    }

    const handleSelectChangeBM = (event) => {
        const bmValue = event.target.value
        setbBmOption(bmValue)
    }

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const handleSelectChangeContract = (event) => {

        const urnValue = event.target.value
        console.log('LSK', urnValue)
        setSelectedOption(urnValue);

        fetch('https://forge.tpfe.madrix.app:5000/dadossetor?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesOpcoesSetor(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadosbm?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesOpcoesBms(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadosvigencia?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setopcoesDataVigencia(data));
/*
        fetch('https://forge.tpfe.madrix.app:5000/dadostabelabms?' + 'numContrato=' + urnValue.split('/')[1].slice(0, 8) + '&instancia=plugbase' + '&setor=&bm=')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => (setOpcoesDataTableBms(data),
                setvaloresPrevistoXExecutado(data.length > 0 ? [data[data.length - 1]] : data)//[data[data.length - 1]]
            ));
*/
        fetch('https://forge.tpfe.madrix.app:5000/dadostabelaservices?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase' + '&setor=' + '')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesDataTableServicos(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadoschartdata?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesPrevistoXExecutado(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadoschecklist_planoacao?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesDataTableCheklists(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadoschecklist?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesDataTableCheklistsQtds(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadoschecklist_qtds_data?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesCheklistsAgrupadosPorData(data));

        fetch('https://forge.tpfe.madrix.app:5000/planoDeacao_qtds_data?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesPlanoDeAcaoAgrupadoPorData(data));

        fetch('https://forge.tpfe.madrix.app:5000/planoDeacao_grau_nconformidade?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesCheklistsGrauNaoConformidade(data));

    }

    useEffect(() => {
        fetch('https://forge.tpfe.madrix.app:5000/token/listObjectsPBB?instancia=plugbase')
            .then(response => response.json())
            .then(data => setOpcoesContrato(data.items));
    }, []);

    return (

        <Box sx={{ flexGrow: 1 }}>
            <AppBar component="nav" style={{ backgroundColor: "#313862", "color": "#fff" }}>
                <Toolbar>
                    
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                    

                    </IconButton>

                    
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex', "align-items": "center","justify-content": "left"} }}
                    >
                        <img src={logo} alt="PLUG" style={{ width: "100px" }}></img>
                        <div style={{"margin-left": "50px"}}>Dashboard de Qualidade</div>
                    </Typography>

                    

    
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{ "color": "#fff" }}>
                        <InputLabel id="setorLabelID" style={{ "color": "#fff" }}>Setor</InputLabel>
                        <Select

                            sx={{
                                color: "white",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}
                            labelId="setorLabelID"
                            id="setorIdSelect"
                            onChange={handleSelectChangeSetor}
                            style={{ "min-width": "200px", "color": "#fff" }}
                            value={opcoesSetorDrop}
                            input={<OutlinedInput label="Setor" />}
                            renderValue={(selected) => console.log('AVA', selected)}

                        >

                            {opcoesSetor.map(opcao => (

                                <MenuItem key={uuidv4} value={opcao.Codigo_WBS}>
                                    <Checkbox checked={opcoesSetorDrop.indexOf(opcao) > -1} />
                                    {console.log('TESTANDO: ', String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS )}
                                    <ListItemText primary={String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS} />
                                </MenuItem>
                                //<MenuItem key={uuidv4} label={opcao.Discriminação_WBS} value={opcao.Codigo_WBS} >{String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{ "color": "#fff" }}>
                        <InputLabel id="naoConformdidadeLabelID" style={{ "color": "#fff" }}>Não Conformidade</InputLabel>
                        <Select



                            sx={{
                                color: "white",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}
                            labelId="naoConformdidadeLabelID"
                            id="naoConformdidadeIdSelect"
                            onChange={handleSelectChangeSetor}
                            style={{ "min-width": "200px", "color": "#fff" }}
                            value={opcoesSetorDrop}
                            input={<OutlinedInput label="Não Conformdidade" />}
                            renderValue={(selected) => console.log('AVA', selected)}

                        >

                            {opcoesSetor.map(opcao => (

                                <MenuItem key={uuidv4} value={opcao.Codigo_WBS}>
                                    <Checkbox checked={opcoesSetorDrop.indexOf(opcao) > -1} />
                                    <ListItemText primary={String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS} />
                                </MenuItem>
                                //<MenuItem key={uuidv4} label={opcao.Discriminação_WBS} value={opcao.Codigo_WBS} >{String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{ "color": "#fff" }}>
                        <InputLabel id="tipoNaoConfirmidadeLabelID" style={{ "color": "#fff" }}>Tipo</InputLabel>
                        <Select



                            sx={{
                                color: "white",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}
                            labelId="tipoNaoConfirmidadeLabelID"
                            id="tipoNaoConfirmidadeIdSelect"
                            onChange={handleSelectChangeSetor}
                            style={{ "min-width": "200px", "color": "#fff" }}
                            value={opcoesSetorDrop}
                            input={<OutlinedInput label="Tipo Não Confirmidade" />}
                            renderValue={(selected) => console.log('AVA', selected)}

                        >

                            {opcoesSetor.map(opcao => (
                                <MenuItem key={uuidv4} value={opcao.Codigo_WBS}>
                                    <Checkbox checked={opcoesSetorDrop.indexOf(opcao) > -1} />
                                    <ListItemText primary={String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS} />
                                </MenuItem>
                                //<MenuItem key={uuidv4} label={opcao.Discriminação_WBS} value={opcao.Codigo_WBS} >{String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="contratoLabelID" style={{ "color": "#fff" }}>Contrato</InputLabel>
                        <Select sx={{
                            color: "white",
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(228, 219, 233, 0.25)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(228, 219, 233, 0.25)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(228, 219, 233, 0.25)',
                            },
                            '.MuiSvgIcon-root ': {
                                fill: "white !important",
                            }
                        }} labelId="contratoLabelID" id="contratoIdSelect" label="Contrato" onChange={handleSelectChangeContract} style={{ "min-width": "200px" }} value={selectedOption}>
                            {opcoesContrato.map(opcao => (
                                <MenuItem key={uuidv4()} label={opcao.objectKey.slice(0, 8)} value={opcao.objectId} >{opcao.objectKey.slice(0, opcao.objectKey.length - 4)}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                </Toolbar>
            </AppBar>

            <Grid container spacing={2} >


                <Grid xs={3}>

                    <OverviewTotalProfit
                        sx={{ height: '64%' }}
                        value={opcoesDataVigencia.map(opcao =>
                            `${new Date(opcao.Data_de_Inicio).toLocaleDateString('pt-BR').toString()} á ${new Date(opcao.Data_de_Termino_Atual_da_Vigencia).toLocaleDateString('pt-BR').toString()}`
                        )}
                        label='Prazo de Vigência'
                        hexcolor='#313862'
                        colorIcon='#62C17E'
                        icon={iconMoeda}

                    />


                </Grid>
                <Grid xs={3}>

                    <OverviewTotalProfit
                        sx={{ height: '64%' }}
                        value={opcoesDataVigencia.map(opcao =>
                            `${opcao.Contratada}`
                        )}
                        label='Contrada'
                        hexcolor='#313862'
                        colorIcon='#62C17E'
                        icon={iconMoeda}
                    />

                </Grid>

                <Grid xs={3}>

                    <OverviewTotalProfit
                        sx={{ height: '64%' }}
                        value={opcoesDataVigencia.map(opcao =>
                            `${opcao.Tipo}`
                        )}
                        label='Tipo'
                        hexcolor='#313862'
                        colorIcon='#62C17E'
                        icon={iconMoeda}
                    />



                </Grid>

                <Grid xs={3}>

                    <OverviewTotalProfit
                        sx={{ height: '64%' }}
                        value={opcoesDataVigencia.map(opcao =>
                            `${opcao.Situacao_do_Contrato}`
                        )}
                        label='Status'
                        hexcolor='#313862'
                        colorIcon='#62C17E'
                        icon={iconMoeda}
                    />



                </Grid>

               

                <Grid xs={7.5}>
                    <Item style={{ height: '60vh' }}><Viewer selectedOption={selectedOption} setorOption={setorOption} /></Item>
                </Grid>

                <Grid xs={1.5}>
                    <Item style={{ height: '12vh', display: 'flex', 'flex-direction': 'column', 'justify-content': 'center', 'align-items': 'center' }}>

                        <div style={{ 'font-size': '16px', 'font-weight': 'bold', 'margin-bottom': '0px', color: 'white' }}>
                            Nº Checklists
                        </div>

                        <div style={{ 'font-size': '48px', color: 'rgba(98, 193, 126, 1)' }}>
                            {opcoesDataTableCheklistsQtds.length}
                        </div>

                    </Item>

                    <Item style={{ height: '13vh', display: 'flex', 'flex-direction': 'column', 'justify-content': 'center', 'align-items': 'center', marginTop: "2vh" }}>

                        <div style={{ 'font-size': '16px', 'font-weight': 'bold', 'margin-bottom': '0px', color: 'white' }}>
                            Nº Não Conformidades
                        </div>

                        <div style={{ 'font-size': '48px', color: '#A1CAEB' }}>
                            {opcoesDataTableCheklists.length}
                        </div>

                    </Item>

                    <Item style={{ height: '28vh', display: 'flex', 'flex-direction': 'column', 'justify-content': 'center', 'align-items': 'center', marginTop: "2vh" }}>

                        <CircularProgress opcoesCheklistsGrauNaoConformidade={opcoesCheklistsGrauNaoConformidade}></CircularProgress>

                    </Item>
                </Grid>

                <Grid xs={3}>
                    <div></div>
                    <Item style={{ height: '28vh' }}>
                        <BarChart opcoesPrevistoXExecutado={opcoesPrevistoXExecutado} opcoesCheklistsAgrupadosPorData={opcoesCheklistsAgrupadosPorData} altura={'23vh'} cabecalho={"Quantidade de Checklists e NCs/Mês"} modulo={"QC"} eixo={'x'} />
                    </Item>
                    
                     <Item style={{ height: '28vh', marginTop: "2vh" }}>
                        <BarChart opcoesPrevistoXExecutado={opcoesPrevistoXExecutado} opcoesPlanoDeAcaoAgrupadoPorData={opcoesPlanoDeAcaoAgrupadoPorData} altura={'23vh'} cabecalho={"Não Conformidades por Tipo"} modulo={"QNC"} eixo={'y'} />
                    </Item>       
                </Grid>
                

                <Grid xs={12}>
                    <Item><TabelaChecklists altura={"300px"} opcoesDataTableCheklists={opcoesDataTableCheklists}/></Item>
                </Grid>

                
            </Grid>
        </Box>

    )
}
export { MainQualidadePB };
