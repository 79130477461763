//import React, {Component} from "react";
import logo from '../../../assets/logo/logoBraskem.png'
import logoTPF from '../../../assets/logo/logoTPF.png'
import Select from '@mui/material/Select';
import React, { Component, useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import BarChart from '../../../Components/Chats/BarChart';
import ProgressChart from '../../../Components/Chats/ProgressChart';

import OverviewTotalProfit from '../../../Components/Tasks/overview-total-profit';
import OverviewTasksProgress from '../../../Components/Tasks/overview-tasks-progress';

import TabelaBms from '../../../Components/Tabelas/TableBMs';
import TabelaServicosSetor from '../../../Components/Tabelas/ServicosBMs'

import Viewer from '../../../Components/PlugBase/Viewer/Viewer'

import { v4 as uuidv4 } from 'uuid'


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

import iconMoeda from '../../../assets/icons/moedaBraskem.png'

import Mydata from '../../../Components/Chats/GanttChart'

//------------------


import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
//import BGanttChart from '../../../Components/Chats/GanttChart';
//----------


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#045B98',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


function MainFisicoFinanceiroBraskem() {

    document.body.style = 'background: #E6E6E6'
    //Recebendo a URN do modelo selecionado no dropDown de Contrato e em seguida o texto do elemento
    const [opcoesContrato, setOpcoesContrato] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
    const [opcoesSetor, setOpcoesOpcoesSetor] = useState([]);
    const [setorOption, setSetorOption] = useState('');

    //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
    const [opcoesBMs, setOpcoesOpcoesBms] = useState([]);
    const [bmOption, setbBmOption] = useState('');

    //Recebendo a data de vigencia referente ao modelo selecionado no dropDown de Contrato
    const [opcoesDataVigencia, setopcoesDataVigencia] = useState([]);

    //Recebendo os dados do BM agrupados para poder atualizar a tabela de BMS
    const [opcoesDataTableBms, setOpcoesDataTableBms] = useState([]);

    //Recebendo os dados do serviços agrupados para poder atualizar a tabela de serviços
    const [opcoesDataTableServicos, setOpcoesDataTableServicos] = useState([]);

    //Recebendo os dados de previsto x executado para atualizar graficos
    const [opcoesPrevistoXExecutado, setOpcoesPrevistoXExecutado] = useState([]);

    const [valoresPrevistoXExecutado, setvaloresPrevistoXExecutado] = useState([]);

    const [setorLabel, setSetorLabel] = useState('Setor');


    const [opcoesSetorDrop, setOpcoesSetorDrop] = useState([]);

    const handleSelectChangeSetor = (event) => {

        const setorValue = event.target.value

        typeof setorValue === 'string' ? console.log('É String') : console.log('não é String')
        setOpcoesSetorDrop(
            // On autofill we get a stringified value.
            typeof setorValue === 'string' ? setorValue.split(',') : setorValue
        );


        const urnValue = document.querySelector("#contratoIdSelect").innerText;
        const bmValue = parseInt(document.querySelector("#bmIdSelect").innerText) > 0 ? document.querySelector("#bmIdSelect").innerText : '';
        console.log(bmValue.length, '<<<<<<<<<<<<')


        setSetorOption(setorValue);


        fetch('https://forge.tpfe.madrix.app:5000/dadostabelabms?' + 'numContrato=' + urnValue + '&setor=' + setorValue + '&bm=' + bmValue + '&instancia=braskem')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => (setOpcoesDataTableBms(data),
                setvaloresPrevistoXExecutado(data.length > 0 ? [data[data.length - 1]] : data)//[data[data.length - 1]]
            ));


        fetch('https://forge.tpfe.madrix.app:5000/dadostabelaservices?' + 'numContrato=' + urnValue + '&setor=' + setorValue + '&instancia=braskem')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesDataTableServicos(data));

        /**
        fetch('https://forge.tpfe.madrix.app:5000/dadoschartdata' + urnValue.split('/')[1].slice(0, 8))
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesPrevistoXExecutado(data));
            */
    }

    const handleSelectChangeBM = (event) => {
        const bmValue = event.target.value
        setbBmOption(bmValue)
    }

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const handleSelectChangeContract = (event) => {

        const urnValue = event.target.value
        setSelectedOption(urnValue);

        fetch('https://forge.tpfe.madrix.app:5000/dadossetor?' + 'numContrato=' + urnValue.split('/')[1].slice(0, 8) + '&instancia=braskem')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesOpcoesSetor(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadosbm?'  + 'numContrato=' + urnValue.split('/')[1].slice(0, 8) + '&instancia=braskem')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesOpcoesBms(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadosvigencia?'  + 'numContrato=' + urnValue.split('/')[1].slice(0, 8) + '&instancia=braskem')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setopcoesDataVigencia(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadostabelabms?' + 'numContrato=' + urnValue.split('/')[1].slice(0, 8) + '&instancia=braskem' + '&setor=&bm=' )
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => (setOpcoesDataTableBms(data),
                setvaloresPrevistoXExecutado(data.length > 0 ? [data[data.length - 1]] : data)//[data[data.length - 1]]
            ));

        fetch('https://forge.tpfe.madrix.app:5000/dadostabelaservices?' + 'numContrato=' + urnValue.split('/')[1].slice(0, 8) +'&instancia=braskem' + '&setor=' + '')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesDataTableServicos(data));

        fetch('https://forge.tpfe.madrix.app:5000/dadoschartdata?' + 'numContrato=' + urnValue.split('/')[1].slice(0, 8) + '&instancia=braskem')
            .then(response => response.json())
            //.then(data => console.log(data));
            .then(data => setOpcoesPrevistoXExecutado(data));

    }

    useEffect(() => {
        fetch('https://forge.tpfe.madrix.app:5000/token/listObjectsPBB?instancia=braskem')
            .then(response => response.json())
            .then(data => setOpcoesContrato(data.items));
    }, []);

    return (

        <Box sx={{ flexGrow: 1 }}>
            <AppBar component="nav" style={{ backgroundImage: "linear-gradient(to right, #F26E03 , #FAC601, #045B98, #00AEE6)", "color": "#fff" }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >

                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        <img src={logo} alt="PLUG" style={{ width: "100px" }}></img>
                    </Typography>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{ "color": "#fff" }}>
                        <InputLabel id="setorLabelID" style={{ "color": "#fff" }}>Setor</InputLabel>
                        <Select

                            
                            
                            sx={{
                                color: "white",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}
                            labelId="setorLabelID"
                            id="setorIdSelect"
                            onChange={handleSelectChangeSetor}
                            style={{ "min-width": "200px", "color": "#fff" }}
                            value={opcoesSetorDrop}
                            input={<OutlinedInput label="Setor"/>}
                            renderValue={(selected) => console.log('AVA', selected)}
                            multiple={true}
                            >
                            
                            {opcoesSetor.map(opcao => (
                                
                                <MenuItem key={uuidv4} value={opcao.Codigo_WBS}>
                                    <Checkbox checked={opcoesSetorDrop.indexOf(opcao) > -1} />
                                    <ListItemText primary={String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS}/>
                                </MenuItem>
                                //<MenuItem key={uuidv4} label={opcao.Discriminação_WBS} value={opcao.Codigo_WBS} >{String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl sx={{ flexGrow: 0.01, m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="contratoLabelID" style={{ "color": "#fff" }}>Obra</InputLabel>
                        <Select sx={{
                            color: "white",
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(228, 219, 233, 0.25)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(228, 219, 233, 0.25)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(228, 219, 233, 0.25)',
                            },
                            '.MuiSvgIcon-root ': {
                                fill: "white !important",
                            }
                        }} labelId="contratoLabelID" id="contratoIdSelect" label="Contrato" onChange={handleSelectChangeContract} style={{ "min-width": "200px" }} value={selectedOption}>
                            {opcoesContrato.map(opcao => (
                                <MenuItem key={uuidv4()} label={opcao.objectKey.slice(0, 8)} value={opcao.objectId} >{opcao.objectKey.slice(0, 8)}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 0.01, display: { xs: 'none', sm: 'flex' } }}
                    >
                        <img src={logoTPF} alt="PLUG" style={{ width: "100px" }}></img>
                    </Typography>

                </Toolbar>
            </AppBar>

            <Grid container spacing={2} >
                <Grid xs={4}>
                    <OverviewTotalProfit
                        sx={{ height: '64%' }}
                        value={`R$ ` + valoresPrevistoXExecutado.map(opcao =>
                            new Intl.NumberFormat('pt-BR').format(opcao.ValorPrevisto_WBS)
                        )}
                        label='Valor Atual do Contrato'
                        hexcolor = '#045B98'
                        colorIcon = '#FFC000'
                        icon = {iconMoeda}
                    />

                </Grid>

                <Grid xs={4}>

                    <OverviewTotalProfit
                        sx={{ height: '64%' }}
                        value={`R$ ` + valoresPrevistoXExecutado.map(opcao =>
                            new Intl.NumberFormat('pt-BR').format(opcao.ValorAcumulado_WBS)
                        )}
                        label='Valor Medido Acumulado'
                        hexcolor = '#045B98'
                        colorIcon = '#FFC000'
                        icon = {iconMoeda}
                    />



                </Grid>

                <Grid xs={4}>

                    <OverviewTotalProfit
                        sx={{ height: '64%' }}
                        value={`R$ ` + valoresPrevistoXExecutado.map(opcao =>
                            new Intl.NumberFormat('pt-BR').format(opcao.ValorAcumulado_WBS)
                        )}
                        label='Saldo Atual do Contrato'
                        hexcolor = '#045B98'
                        colorIcon = '#FFC000'
                        icon = {iconMoeda}
                    />



                </Grid>

                <Grid xs={7.5}>
                    <Item style={{ height: '60vh' }}><Viewer selectedOption={selectedOption} setorOption={setorOption} /></Item>
                </Grid>

                <Grid xs={4.5}>
                    <Item><TabelaServicosSetor altura ={'60vh'} opcoesDataTableServicos={opcoesDataTableServicos} /></Item>
                </Grid>

                <Grid xs={12}>
                    <Item><Mydata altura ={'40vh'}/></Item>
                </Grid>
            </Grid>
        </Box>
    )

}

export { MainFisicoFinanceiroBraskem};
