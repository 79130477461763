import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { IntlProvider, FormattedMessage } from 'react-intl';
import moment from 'moment';

import messages from '../../assets/messagensTraduzidas/pt-BR.json'
moment.locale('pt-br');

const tasks = [
  {
    start: new Date(2023, 1, 1),
    end: new Date(2023, 1, 2),
    name: 'Task 0',
    id: 'Task 0',
    type: 'task',
    progress: 45,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },

  },
  {
    start: new Date(2023, 1, 2),
    end: new Date(2023, 1, 3),
    name: 'Task 1',
    id: 'Task 1',
    type: 'task',
    progress: 0,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    dependencies: '',
  },
  {
    start: new Date(2023, 1, 3),
    end: new Date(2023, 1, 6),
    name: 'Task 3',
    id: 'Task 3',
    type: 'task',
    progress: 0,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    dependencies: ['Task 0', 'Task 1'],
  },
  {
    start: new Date(2023, 1, 5),
    end: new Date(2023, 1, 7),
    name: 'Task 4',
    id: 'Task 4',
    type: 'task',
    progress: 0,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    dependencies: '',
  },
  {
    start: new Date(2023, 1, 5),
    end: new Date(2023, 1, 7),
    name: 'Task 4',
    id: 'Task 4',
    type: 'task',
    progress: 0,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    dependencies: '',
  },
  {
    start: new Date(2023, 1, 5),
    end: new Date(2023, 1, 7),
    name: 'Task 4',
    id: 'Task 4',
    type: 'task',
    progress: 0,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    dependencies: '',
  },
];

function Mydata(props) {

  return (
    <div style={{ height: props.altura, "max-height": props.altura, width: '100%', "overflow-y": "auto"}}>
      <IntlProvider locale="pt-BR" messages={messages}>
        <Gantt tasks={tasks}
          viewMode={'Day'}
          locale={moment}
          style={{ height:"100%"}}
        />
      </IntlProvider>
    </div>

  )
};

export default Mydata;