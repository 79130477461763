//import React, {Component} from "react";
import logo from '../../../assets/logo/logoPlug.png'
import Select from '@mui/material/Select';
import React, { Component, useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import BarChart from '../../../Components/Chats/BarChart';
import ProgressChart from '../../../Components/Chats/ProgressChart';

import OverviewTotalProfit from '../../../Components/Tasks/overview-total-profit';
import OverviewTasksProgress from '../../../Components/Tasks/overview-tasks-progress';

import TabelaBms from '../../../Components/Tabelas/TableBMs';
import TabelaServicosSetor from '../../../Components/Tabelas/ServicosBMs'

import Viewer from '../../../Components/PlugBase/Viewer/Viewer'

import { v4 as uuidv4 } from 'uuid'


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

//------------------
import iconMoeda from '../../../assets/icons/moeda.png'


import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';


import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
//----------


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#313862',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const PainelItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
    marginTop: '8px',
}));



function MainFinanceiroPB() {
    //Recebendo a URN do modelo selecionado no dropDown de Contrato e em seguida o texto do elemento
    const [opcoesContrato, setOpcoesContrato] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
    const [opcoesSetor, setOpcoesOpcoesSetor] = useState([]);
    const [setorOption, setSetorOption] = useState([]);

    //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
    const [opcoesBMs, setOpcoesOpcoesBms] = useState([]);
    const [bmOption, setbBmOption] = useState('');

    //Recebendo a data de vigencia referente ao modelo selecionado no dropDown de Contrato
    const [opcoesDataVigencia, setopcoesDataVigencia] = useState([]);

    //Recebendo os dados do BM agrupados para poder atualizar a tabela de BMS
    const [opcoesDataTableBms, setOpcoesDataTableBms] = useState([]);

    //Recebendo os dados do serviços agrupados para poder atualizar a tabela de serviços
    const [opcoesDataTableServicos, setOpcoesDataTableServicos] = useState([]);

    //Recebendo os dados de previsto x executado para atualizar graficos
    const [opcoesPrevistoXExecutado, setOpcoesPrevistoXExecutado] = useState([]);

    const [valoresPrevistoXExecutado, setvaloresPrevistoXExecutado] = useState([]);

    const [setorLabel, setSetorLabel] = useState('Setor');


    const [opcoesSetorDrop, setOpcoesSetorDrop] = useState([]);

    //--------
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleOptionToggle = (option) => () => {

        
        const selectedIndex = selectedOptions.indexOf(option);

        
        let newSelectedOptions = [];

        if (selectedIndex === -1) {
            newSelectedOptions = [...selectedOptions, option];
        } else {
            newSelectedOptions = selectedOptions.filter((item) => item !== option);
        }

        setSelectedOptions(newSelectedOptions);

        const urnValue = document.querySelector("#contratoIdSelect").innerText + '.nwd';
        const bmValue = parseInt(document.querySelector("#bmIdSelect").innerText) > 0 ? document.querySelector("#bmIdSelect").innerText : '';
        //console.log('MMMMMMMMMMMMMMMMMMMM: ', urnValue)

        fetch('https://forge.tpfe.madrix.app:5000/dadostabelabms?' + 'numContrato=' + urnValue + '&setor=' + newSelectedOptions + '&bm=' + bmValue + '&instancia=plugbase')
                .then(response => response.json())
                //.then(data => console.log(data));
                .then(data => (setOpcoesDataTableBms(data),
                    setvaloresPrevistoXExecutado(data.length > 0 ? [data[data.length - 1]] : data)//[data[data.length - 1]]
                ));

        fetch('https://forge.tpfe.madrix.app:5000/dadostabelaservices?' + 'numContrato=' + urnValue + '&setor=' + newSelectedOptions + '&instancia=plugbase')
                .then(response => response.json())
                //.then(data => console.log(data));
                .then(data => setOpcoesDataTableServicos(data));

        setSetorOption(newSelectedOptions)
    }

        //-------

        const handleSelectChangeSetor = (event) => {

            const setorValue = event.target.value

            typeof setorValue === 'string' ? console.log('É String') : console.log('não é String')
            setOpcoesSetorDrop(
                // On autofill we get a stringified value.
                typeof setorValue === 'string' ? setorValue.split(',') : setorValue
            );


            const urnValue = document.querySelector("#contratoIdSelect").innerText + '.nwd';

            const bmValue = parseInt(document.querySelector("#bmIdSelect").innerText) > 0 ? document.querySelector("#bmIdSelect").innerText : '';
            console.log(bmValue.length, '<<<<<<<<<<<<')

            //setSetorOption(setorValue);


            // fetch('https://forge.tpfe.madrix.app:5000/dadostabelabms?' + 'numContrato=' + urnValue + '&setor=' + setorValue + '&bm=' + bmValue + '&instancia=plugbase')
            //     .then(response => response.json())
            //     //.then(data => console.log(data));
            //     .then(data => (setOpcoesDataTableBms(data),
            //         setvaloresPrevistoXExecutado(data.length > 0 ? [data[data.length - 1]] : data)//[data[data.length - 1]]
            //     ));


            // fetch('https://forge.tpfe.madrix.app:5000/dadostabelaservices?' + 'numContrato=' + urnValue + '&setor=' + setorValue + '&instancia=plugbase')
            //     .then(response => response.json())
            //     //.then(data => console.log(data));
            //     .then(data => setOpcoesDataTableServicos(data));

            /**
            fetch('https://forge.tpfe.madrix.app:5000/dadoschartdata' + urnValue.split('/')[1].slice(0, 8))
                .then(response => response.json())
                //.then(data => console.log(data));
                .then(data => setOpcoesPrevistoXExecutado(data));
                */
        }

        const handleSelectChangeBM = (event) => {
            const bmValue = event.target.value
            setbBmOption(bmValue)
        }

        const [mobileOpen, setMobileOpen] = React.useState(false);

        const handleDrawerToggle = () => {
            setMobileOpen((prevState) => !prevState);
        };


        const handleSelectChangeContract = (event) => {

            const urnValue = event.target.value
            setSelectedOption(urnValue);

            fetch('https://forge.tpfe.madrix.app:5000/dadossetor?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
                .then(response => response.json())
                //.then(data => console.log(data));
                .then(data => setOpcoesOpcoesSetor(data));

            fetch('https://forge.tpfe.madrix.app:5000/dadosbm?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
                .then(response => response.json())
                //.then(data => console.log(data));
                .then(data => setOpcoesOpcoesBms(data));

            fetch('https://forge.tpfe.madrix.app:5000/dadosvigencia?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
                .then(response => response.json())
                //.then(data => console.log(data));
                .then(data => setopcoesDataVigencia(data));

            fetch('https://forge.tpfe.madrix.app:5000/dadostabelabms?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase' + `&setor=&bm=`)
                .then(response => response.json())
                //.then(data => console.log(data));
                .then(data => (setOpcoesDataTableBms(data),
                    setvaloresPrevistoXExecutado(data.length > 0 ? [data[data.length - 1]] : data)//[data[data.length - 1]]
                ));

            fetch('https://forge.tpfe.madrix.app:5000/dadostabelaservices?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase' + '&setor=' + '')
                .then(response => response.json())
                //.then(data => console.log(data));
                .then(data => setOpcoesDataTableServicos(data));

            fetch('https://forge.tpfe.madrix.app:5000/dadoschartdata?' + 'numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
                .then(response => response.json())
                //.then(data => console.log(data));
                .then(data => setOpcoesPrevistoXExecutado(data));



        }

        useEffect(() => {
            fetch('https://forge.tpfe.madrix.app:5000/token/listObjectsPBB?instancia=plugbase')
                .then(response => response.json())
                .then(data => setOpcoesContrato(data.items));
        }, []);

        return (

            <Box sx={{ flexGrow: 1 }}>
                <AppBar component="nav" style={{ backgroundColor: "#313862", "color": "#fff" }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >

                        </IconButton>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            <img src={logo} alt="PLUG" style={{ width: "100px" }}></img>
                        </Typography>

                        {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{ "color": "#fff" }}>
                            <InputLabel id="setorLabelID" style={{ "color": "#fff" }}>Setor</InputLabel>
                            <Select

                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}
                                labelId="setorLabelID"
                                id="setorIdSelect"
                                onChange={handleSelectChangeSetor}
                                style={{ "min-width": "200px", "color": "#fff" }}
                                value={opcoesSetorDrop}
                                input={<OutlinedInput label="Setor" />}
                                renderValue={(selected) => console.log('AVA', selected)}

                            >

                                {opcoesSetor.map(opcao => (


                                    <MenuItem key={opcao.Codigo_WBS} value={opcao.Codigo_WBS}>
                                        <Checkbox checked={opcoesSetorDrop.indexOf(opcao) > -1} />
                                        <ListItemText primary={String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length) + opcao.Discriminação_WBS} />
                                    </MenuItem>
                                    //<MenuItem key={uuidv4} label={opcao.Discriminação_WBS} value={opcao.Codigo_WBS} >{String.fromCharCode(8194).repeat((opcao.Codigo_WBS.match(/./g) || []).length - 3) + opcao.Discriminação_WBS}</MenuItem>
                                ))}

                            </Select>
                        </FormControl> */}

                        <FormControl sx={{ m: 1, minWidth: "120px", maxWidth: '200px',  overflow: 'auto' }} size="small" style={{ "color": "#fff" }}>
                            <InputLabel style={{ "color": "#fff" }}>Setor</InputLabel>
                            <Select
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}
                                multiple
                                value={selectedOptions}
                                onChange={(event) => setSelectedOptions(event.target.value)}
                                renderValue={(selected) => selected.join(', ')}
                                style={{ "min-width": "200px", "color": "#fff" }}
                            >
                                {opcoesSetor.map((option) => (
                                    <MenuItem key={uuidv4} value={option.Codigo_WBS}>
                                        <Checkbox checked={selectedOptions.indexOf(option.Codigo_WBS) > -1} onChange={handleOptionToggle(option.Codigo_WBS)} />
                                        <ListItemText primary={String.fromCharCode(8194).repeat((option.Codigo_WBS.match(/./g) || []).length) + option.Discriminacao_WBS} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="bmLabelID" style={{ "color": "#fff" }}>BM</InputLabel>
                            <Select sx={{
                                color: "white",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }} labelId="bmLabelID" id="bmIdSelect" label="BM" onChange={handleSelectChangeBM} style={{ "min-width": "200px" }} value={bmOption}>
                                <MenuItem key={uuidv4} label={"Todos"} value={""}>{"Todos"}</MenuItem>
                                {opcoesBMs.map(opcao => (
                                    <MenuItem key={uuidv4} label={opcao.BM} value={opcao.BM}>{opcao.BM}</MenuItem>

                                ))}

                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="contratoLabelID" style={{ "color": "#fff" }}>Contrato</InputLabel>
                            <Select sx={{
                                color: "white",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }} labelId="contratoLabelID" id="contratoIdSelect" label="Contrato" onChange={handleSelectChangeContract} style={{ "min-width": "200px" }} value={selectedOption}>
                                {opcoesContrato.map(opcao => (
                                    //<MenuItem key={uuidv4()} label={opcao.objectKey.slice(0, 8)} value={opcao.objectId} >{opcao.objectKey.slice(0, 8)}</MenuItem>
                                    <MenuItem key={uuidv4()} label={opcao.objectKey.slice(0, 8)} value={opcao.objectId} >{opcao.objectKey.slice(0, opcao.objectKey.length - 4)}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>

                        

                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} >


                    <Grid xs={3}>

                        <OverviewTotalProfit
                            sx={{ height: '64%' }}
                            value={opcoesDataVigencia.map(opcao =>
                                `${new Date(opcao.Data_de_Inicio).toLocaleDateString('pt-BR').toString()} á ${new Date(opcao.Data_de_Termino_Atual_da_Vigencia).toLocaleDateString('pt-BR').toString()}`
                            )}
                            label='Prazo de Vigência'
                            hexcolor='#313862'
                            colorIcon='#62C17E'
                            icon={iconMoeda}

                        />


                    </Grid>
                    <Grid xs={3}>

                        <OverviewTotalProfit
                            sx={{ height: '64%' }}
                            value={`R$ ` + valoresPrevistoXExecutado.map(opcao =>
                                new Intl.NumberFormat('pt-BR').format(opcao.ValorPrevisto_WBS)
                            )}
                            label='Valor Previsto'
                            hexcolor='#313862'
                            colorIcon='#62C17E'
                            icon={iconMoeda}
                        />

                    </Grid>

                    <Grid xs={3}>

                        <OverviewTotalProfit
                            sx={{ height: '64%' }}
                            value={`R$ ` + valoresPrevistoXExecutado.map(opcao =>
                                new Intl.NumberFormat('pt-BR').format(opcao.ValorAcumulado_WBS)
                            )}
                            label='Valor Executado'
                            hexcolor='#313862'
                            colorIcon='#62C17E'
                            icon={iconMoeda}
                        />



                    </Grid>

                    <Grid xs={3}>
                        <OverviewTasksProgress
                            sx={{ height: '64%' }}
                            value={opcoesDataTableBms}
                            label={"Avanço Financeiro"}
                        />
                    </Grid>

                    <Grid xs={7.5}>
                        <Item style={{ height: '60vh' }}><Viewer selectedOption={selectedOption} setorOption={setorOption} /></Item>
                    </Grid>

                    <Grid xs={4.5}>
                        <Item style={{ height: '60vh' }} ><BarChart opcoesPrevistoXExecutado={opcoesPrevistoXExecutado} altura={"50vh"} cabecalho={"Previsto x Realizado - Execução Financeira"} modulo={"F"} eixo={'x'} /></Item>
                    </Grid>

                    <Grid xs={6.5}>
                        <Item><TabelaBms altura={"300px"} opcoesDataTableBms={opcoesDataTableBms} /></Item>
                    </Grid>

                    <Grid xs={5.5}>
                        <Item><TabelaServicosSetor altura={"300px"} opcoesDataTableServicos={opcoesDataTableServicos} /></Item>
                    </Grid>
                </Grid>
            </Box>

        )

    }

    export { MainFinanceiroPB };
