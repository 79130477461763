import React from 'react';
import { Doughnut } from 'react-chartjs-2';


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
    
  );



const options = {
  cutout: '70%',
  plugins: {
    legend: {
      display: "relative",
    },
  },
};

function ProgressChart(props){

  let acumulado = 0
  let previsto = 100

  try {
    acumulado = parseFloat(props.opcoesDataTableBms[props.opcoesDataTableBms.length -1].ValorAcumulado_WBS)
    previsto = parseFloat(props.opcoesDataTableBms[props.opcoesDataTableBms.length -1].ValorPrevisto_WBS)
  } catch (error) {
    
  }

  const data = {
    labels: ['Concluído', 'Pendente'],
    datasets: [
      {
        label: 'Progresso',
        data: [(acumulado/previsto)*100, 100 - ((acumulado/previsto)*100)],
        backgroundColor: ['#62C17E', '#D3D3D3'],
        borderWidth: 1,
      },
    ],
  };
  
  return(
    <>
    <Doughnut data={data} options={options} />
  </>
  )
 
};

export default ProgressChart;